import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return



	/** Swiper Inovação | Comunicação | Crescimento */
	const SwiperEducativos = new Swiper(".swiper-educativos", {
		slidesPerView: 1.4,
		spaceBetween: 24,

		centeredSlidesBounds: true,
		centeredSlides: true,

		breakpoints: {
			600: {
				spaceBetween: 32,
				slidesPerView: 3,
			},
			1024: {
				spaceBetween: 80,
				slidesPerView: 4,
				pagination: false,
				centeredSlidesBounds: false,
				centeredSlides: false,
			},
			1440: {
				slidesPerView: 5,
				spaceBetween: 80,

			}
		},
		pagination: {
			clickable: true,
			el: '#materias-educativos .swiper-pagination',
		},

		navigation: {
			nextEl: '#materias-educativos .swiper-button-next',
			prevEl: '#materias-educativos .swiper-button-prev',
		}

	})

	const swiperDicasLeitura = new Swiper(".swiper-dicas-leitura", {
		slidesPerView: 1.2,
		spaceBetween: 24,
		pagination: {
			clickable: true,
			el: '.swiper-dicas-leitura .swiper-pagination',
		},
		breakpoints: {
			767: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			}
		}
	})


}