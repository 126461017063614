export default function estimateReadingTime(jsonContent, wordsPerMinute = 250) {
    const blocks = JSON.parse(jsonContent).blocks;
    let totalWords = 0;

    blocks.forEach(block => {
        let text = '';

        if (block.type === 'Header' || block.type === 'paragraph') {
            text = block.data.text;
        } else if (block.type === 'List') {
            text = block.data.items.join(' ');
        }

        // Remove HTML tags and count words
        const cleanText = text.replace(/<[^>]*>/g, '');
        totalWords += cleanText.split(/\s+/).filter(word => word.length > 0).length;
    });

    console.log('total palavras' , totalWords) 

    // Calculate reading time in minutes
    const readingTimeMinutes =  Math.ceil(totalWords / wordsPerMinute);
    return Math.round(readingTimeMinutes * 100) / 100;  // Rounded to 2 decimal places
}