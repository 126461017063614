import axios from 'axios'


import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import PageBusca from "./pages/Busca";
import PagePublique from "./pages/Publique";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import MegaMenu from './modules/MegaMenu';
import PageProdutos from './pages/Produtos';
import singleProduto from './pages/SingleProduto';
import Autores from './pages/Autores';
import SingleAutor from './pages/Singleautor';
import Blog from './pages/Blog';
import PageSingleblog from './pages/SingleDetail';

import LenisScroll from './animation/lenis';
import splitLetters from './animation/splitLetters';
import EditorJs from './modules/editorJs';
import PageMateriasGratuitos from './pages/Materiais-gratuitos';
// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
// ripple()
formErros()

splitLetters()
animateSetup()
animaAoScroll()

LenisScroll()
EditorJs()


// menuglobal
MegaMenu()

// Pages
PageHome()
PageBusca()
PagePublique()
PageProdutos()
singleProduto()
Autores()
SingleAutor()
Blog()
PageSingleblog()
PageMateriasGratuitos()
PageLogin()?.init()
// utils logs
logs()



