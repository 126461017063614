// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import { find } from "../utils/dom"

// gsap.registerPlugin(ScrollTrigger);
export default function PageProdutos(){

    const Page = find("[js-page='produtos']")
	if (!Page) return
 

    const btnFilter = document.querySelector('[js-open-filtro]')
    const closeModal = document.querySelector('[js-closeModal]')
 
    const accordionsItems = document.querySelectorAll('.custom-sidebar-filtro-produtos > ul > li:first-child')

    const filtrarSelect = document.querySelector('select#filtrar')
    const ordernarForm = document.querySelector('form#ordernarForm')
    

    function filterProdutos(){
        btnFilter.addEventListener('click', () =>{
            document.body.classList.add('show-filter')
        })
        closeModal.addEventListener('click', () =>{
            document.body.classList.remove('show-filter')
        })
    }

    function accordionFiltros(){

       
        accordionsItems.forEach( li =>{
                li.addEventListener('click', (e) =>{
                 
                    const target = e.target.closest('ul')
                    if(!target) return
                    target.classList.toggle('active')
                })
        })
    }

    
    filtrarSelect && filtrarSelect.addEventListener('change', () =>{
        ordernarForm.submit()
    })

    accordionFiltros()
    filterProdutos()
  
    // js-open-filtro
}