import Swiper from "swiper"
import { find } from "../utils/dom"
import shareUrl from "../modules/sharedURL";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default function singleProduto(){

    const Page = find("[js-page='singleProduto']")
	if (!Page) return

    const firstVariantsFilter = document.querySelectorAll('[js-filter-links] .produto-variante');
    const filterItemsContainer = document.querySelector('[js-filter-items]');

    const firstProductInfoFilter = document.querySelectorAll('[js-filter-content-tab] .product-info-tab');
    const filterProductInfoContainer = document.querySelector('[js-filter-content]');
    const lupa = document.querySelector('#lupa-icon-js')
    
 
    function startTabs(Items, firstItemActive, display){
        console.log(display)
        console.log(Items)
        if (Items && firstItemActive) {
            // Set the initial state to show the first group
            const initialGroup = firstItemActive.querySelector('[data-format]');
            const firstAbove = document.querySelector('[js-filter-links] [data-format]');
            if(firstAbove){
                firstAbove.classList.add('active')
            }
            if (initialGroup) {
                initialGroup.style.display = display;
            }
        
            Items.forEach(item => {
                item.addEventListener('click', (e) => {
                    // Remove active class from all items
                    Items.forEach(i => i.classList.remove('active'));
                    // Add active class to the clicked item
                    e.currentTarget.classList.add('active');
        
                    const selectedFormat = e.currentTarget.getAttribute('data-format');
                    const filterGroups = firstItemActive.querySelectorAll('[data-format]');
                  
                    filterGroups.forEach(group => {
                     
                        if (group.getAttribute('data-format') === selectedFormat) {
                            group.style.display = display;  // Show the group
                        } else {
                            group.style.display = 'none';  // Hide the group
                        }
                    });
                });
            });
        }
    }

    const jsShareMobile = document.querySelector('[js-open-pop-up-share]')
    function openShareMobile() {
        if (jsShareMobile) {
            jsShareMobile.addEventListener('click', (event) => {
                const shareWrapper = document.querySelector('#share-wrapper');
    
                // Adiciona a classe 'active' com um pequeno atraso para evitar o problema de clique imediato
                setTimeout(() => {
                    shareWrapper.classList.add('active');
                }, 0);
    
                // Adiciona um listener para fechar ao clicar fora
                function handleOutsideClick(event) {
                    // Verifica se o clique foi fora do wrapper de compartilhamento
                    if (!shareWrapper.contains(event.target) && event.target !== jsShareMobile) {
                        shareWrapper.classList.remove('active');
                        // Remove o listener após fechar
                        document.removeEventListener('click', handleOutsideClick);
                    }
                }
    
                // Adiciona o listener de clique fora
                document.addEventListener('click', handleOutsideClick);
                
                // Evita que o clique no botão de abrir o popup feche o popup imediatamente
                event.stopPropagation();
            });
        }
    }
    openShareMobile()

    startTabs(firstVariantsFilter, filterItemsContainer, 'flex')
    startTabs(firstProductInfoFilter, filterProductInfoContainer, 'block')

    shareUrl()
    	
   function clickToZoom(){

    
    lupa && lupa.addEventListener('click', ()=>{
        const currentSlide = MainProduto.slides[MainProduto.activeIndex]
        if(!currentSlide)return
        const imgUrl = currentSlide.querySelector('img').src

            

        new Fancybox(
            [
              {
                src: imgUrl, // Replace with the path to your image
                type: "image",
              },
            ],
            {
              // Your custom options
              // For example, you can customize the animation, buttons, etc.
              loop: true, // Allow looping through images
              toolbar: true, // Show toolbar
              buttons: ["zoom", "fullScreen", "close"], // Buttons to show in the Fancybox
            }
          );
    })
   }

   
   function changeHrefIsbn(){

        const isbns = document.querySelectorAll('[js-items-isbn] .produto-variante');
        const lojas = document.querySelectorAll('[data-link-pradrao]');

        isbns && isbns.forEach(isbn => {
            isbn.addEventListener('click', (e) =>{
                const isbnLojas = isbn.getAttribute('data-isbn')
                isbns.forEach(i => i.classList.remove('active'))
                isbn.classList.add('active')
               
                lojas.forEach(loja =>{
                    const  linkpadrao = loja.getAttribute('data-link-pradrao')
                    loja.href= `${linkpadrao.replace('<<<isbn>>>', isbnLojas)}`
                })
            })
          
           
          
        })

    }
    changeHrefIsbn()
    // swipers 

    let ThumbsSwiper = new Swiper(".swiper-single-thumbs", {
        direction: "vertical",
        slidesPerView: "auto",
        spaceBetween: 19,
        watchSlidesProgress: true,
       
      });

    let MainProduto =  new Swiper(".swiper-main-product", {
        slidesPerView: 'auto',
        spaceBetween: 16,
        pagination:{
            el:'.swiper-main-product .swiper-pagination'
        },
        thumbs: {
            swiper: ThumbsSwiper,
          },
    })
    let swiperProductInfo = new Swiper(".swiper-product-info",{
        slidesPerView: 'auto',
        breakpoints: {
            768:{
                slidesPerView: 3,
            }
        }     
    })

    let swiperAvaliacao = new Swiper(".swiper-avaliacao",{
        slidesPerView: 1.2,
        spaceBetween:16,
        pagination:{
            el:".swiper-avaliacao .swiper-pagination",
            clickable:true
        },
        navigation:{
            nextEl: ".swiper-avaliacao .swiper-button-next",
            prevEl: ".swiper-avaliacao .swiper-button-prev"
        },
        breakpoints:{
            600:{
                slidesPerView: 2,
                spaceBetween:24,
                slidesPerGroup:2
            },
            768:{
                slidesPerView: 3,
                spaceBetween:24,
                slidesPerGroup:3
            },
            1024:{
                slidesPerView: 4,
                slidesPerGroup:4,
                spaceBetween:24
            }
        }
    })
    
    clickToZoom()
}