import Swiper from "swiper"
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import { find } from "utils/dom"


export default function () {
	const Publique = find("[js-page='publique-seu-livro']")
	if (!Publique) return



	/** Swiper Inovação | Comunicação | Crescimento */

    const propsNaMidia = (props, reverse=false) => {

   
        return {
            reverseDirection: true,
            slidesPerView: 1.2,
            spaceBetween: 24,
            noSwiping: true,
            noSwipingClass:'noSwipingClass',

            speed: 3000,
            loop:true,
            ...props,
            autoplay:{
                delay: 1,
                reverseDirection:reverse
            },
            // centeredSlidesBounds: true,
            // centeredSlides: true,
    
            breakpoints: {
            	
            	1024: {
            		spaceBetween: 24,
            		slidesPerView: 3,
            		pagination: false
            	},
            	
            },
            // pagination: {
            // 	clickable: true,
            // 	el: '#materias-educativos .swiper-pagination',
            // },
    
            // navigation: {
            // 	nextEl: '#materias-educativos .swiper-button-next',
            // 	prevEl: '#materias-educativos .swiper-button-prev',
            // }
        }
    }
	const naMidia1 = new Swiper(".swiper-na-midia-1", propsNaMidia())
	const naMidia2 = new Swiper(".swiper-na-midia-2", propsNaMidia({}, true))
   

    console.log('rodou ?')
    console.log(Fancybox)
    Fancybox.bind('[data-fancybox="gallery-marketing"]', {
        // Your custom options for a specific gallery
      });

}
