
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from 'lenis'

export default function LenisScroll(){


    const lenis = new Lenis()

    window.lenisInstance = lenis
    // lenis.on('scroll', (e) => {
    // console.log(e)
    // })

    lenis.on('scroll', ScrollTrigger.update)

    gsap.ticker.add((time)=>{
    lenis.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)


    
    document.querySelectorAll('.scrollable-fix').forEach(container => {
        container.addEventListener('wheel', (event) => {
            event.preventDefault();
            event.stopPropagation();
            container.scrollTop += event.deltaY;
        });
    });
}