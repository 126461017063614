import estimateReadingTime from "./estimateReadTime";

export default function EditorJs(){
    
    function parseJSONToHTML(json) {
        
        if(!json ||  json == 'None') return
        const data = JSON.parse(json);
        console.log(data)
        
        let html = '';
        let insideGrid = false;
        let currentColumn = '';
    
        for (const block of data.blocks) {
            if (block.type === 'paragraph') {
                const text = block.data.text.trim();
    
                if (text === '[#init-grid]') {
                    html += '<div class="grid-container">';
                    insideGrid = true;
                    continue;
                }
    
                if (text === '[#end-grid]' && insideGrid) {
                    if (currentColumn) {
                        html += '</div>'; // Fechar a última coluna
                        currentColumn = '';
                    }
                    html += '</div>'; // Fechar o grid-container
                    insideGrid = false;
                    continue;
                }
    
                const colMatch = text.match(/\[#col-(\d+)\]/);
                if (colMatch && insideGrid) {
                    if (currentColumn) {
                        html += '</div>'; // Fechar a coluna anterior
                    }
                    currentColumn = `col-${colMatch[1]}`;
                    html += `<div class="${currentColumn}">`;
                    continue;
                }
            }
    
            // Adicionar conteúdo dentro da coluna atual ou do grid
            if (insideGrid && currentColumn) {
                html += handleBlock(block);
            } else if (!insideGrid) {
                // Adicionar conteúdo fora do grid normalmente
                html += handleBlock(block);
            }
        }
    
        // Fechar qualquer coluna ou grid-container que permaneça aberto
        if (insideGrid) {
            if (currentColumn) {
                html += '</div>'; // Fechar a última coluna
            }
            html += '</div>'; // Fechar o grid-container
        }
    
        return html;
    }
    
    // Função auxiliar para tratar diferentes tipos de blocos
    function handleBlock(block) {
        let blockHTML = '';
    
        switch (block.type.toLowerCase()) { // Converter o tipo para minúsculas
            case 'paragraph':
                blockHTML = `<p class="paragraph">${block.data.text}</p>`;
                break;
            case 'header':
                blockHTML = `<h${block.data.level} class="header">${block.data.text}</h${block.data.level}>`;
                break;
            case 'table':
                // blockHTML = `<table class="table">${block.data.content}</table>`;
                blockHTML = '<div class="table-container"><table class="table">';
    
                block.data.content.forEach((row, rowIndex) => {
                    if (rowIndex === 0) {
                        // First row as header
                        blockHTML += '<thead><tr>';
                        row.forEach(cell => {
                            blockHTML += `<th>${cell}</th>`;
                        });
                        blockHTML += '</tr></thead><tbody>';
                    } else {
                        // Other rows as body
                        blockHTML += '<tr>';
                        row.forEach(cell => {
                            blockHTML += `<td>${cell}</td>`;
                        });
                        blockHTML += '</tr>';
                    }
                });
            
                blockHTML += '</tbody></table></div>';
                break;
            case 'raw':
                blockHTML = `<div class="raw">${block.data.html}</div>`;
                break;
            case 'delimiter':
                blockHTML = '<hr class="delimiter">';
                break;
            case 'code':
                blockHTML = `<pre class="code"><code>${block.data.code}</code></pre>`;
                break;
            case 'quote':
                blockHTML = `<blockquote class="quote">${block.data.text}</blockquote>`;
                break;
            case 'list':
                const listItems = block.data.items.map(item => `<li>${item}</li>`).join('');
                const listStyle = block.data.style === 'ordered' ? 'ol' : 'ul';
                blockHTML = `<${listStyle} class="list">${listItems}</${listStyle}>`;
                break;
            case 'embed':
                blockHTML = `
                <div class="wrapper-embed">
                    <div class="embed ${block.data.service}">
                        <iframe width="${block.data.width}" height="${block.data.height}" src="${block.data.embed}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>`;
                break;
            case 'checklist':
                const checklistItems = block.data.items.map(item => {
                    const checked = item.checked ? 'checked' : '';
                    return `<li>
                        <input type="checkbox" onclick="return false;" ${checked}>
                        <label>${item.text}</label>
                    </li>`;
                }).join('');
                blockHTML = `<ul>${checklistItems}</ul>`;
                break;
            case 'image':
                blockHTML = `<div class="image-container"><img src="${block.data.file.url || 'default.jpg'}" alt="${block.data.caption}" class="image"></div>`;
                break;
            case 'warning':
                blockHTML = `<div class="warning">
                    <h3>${block.data.title}</h3>
                    <p>${block.data.message}</p>
                </div>`;
                break;
            default:
                // Tratar tipos de blocos desconhecidos aqui
                blockHTML = `<div class="unknown-block">Tipo desconhecido: ${block.type}</div>`;
                break;
        }
    
        return blockHTML;
    }

    const blogContentEls = document.querySelectorAll('[editor-js-init]')
   



    blogContentEls && blogContentEls.forEach((blogContent) =>{


        if (blogContent.dataset.conteudo) {
            const htmlParsed = parseJSONToHTML(blogContent.dataset.conteudo)
            // console.log(htmlParsed);
            blogContent.innerHTML = htmlParsed


        } else {
            console.log("talvez voce esqueceu do ? data-conteudo='' ")
            console.log('SEM CONTEUDO ;')
        }
    })



    // read time 
    const blogs = document.querySelectorAll('[editor-js-read-time]')
    if(blogs){
        blogs.forEach(blog =>{
            const content = blog.dataset.conteudo
        
            
            if(content && content != 'None'){

                const readTime =  estimateReadingTime(content)
                console.log('readtime',readTime)
                if(readTime){
                   const readtime =  blog.querySelector('.dinamico-read-time')
                   readtime.innerText = `${readTime} `;
                }
            }
        })

       

    }

     
    // ANCORAGEM  EDITOR JS 
    // Select all img elements with alt starting with "#" or "https://"
    
        const imgElements = document.querySelectorAll('img[alt^="#"], img[alt^="https://"]');

        // Add click event listener to each image
        if (imgElements) {
            imgElements.forEach(img => {
                img.addEventListener('click', function() {
                    // Get the alt attribute value
                    const altValue = img.getAttribute('alt');

                    if (altValue.startsWith("#")) {
                        // Handle scrolling to a section
                        const targetSection = document.querySelector(altValue);
                        if (targetSection) {
                            targetSection.scrollIntoView({ behavior: 'smooth' });
                        } else {
                            console.warn(`No section found with selector: ${altValue}`);
                        }
                    } else if (altValue.startsWith("https://")) {
                        // Handle navigation to a link
                        window.location.href = altValue;
                    } else {
                        console.warn(`Unsupported alt attribute format: ${altValue}`);
                    }
                });
            });
        }
}