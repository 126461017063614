import Swiper from "swiper";
import { find } from "../utils/dom"
export default function Autores(){
	const Page = find("[js-page='autores']")
	if (!Page) return

    // swiper-filter-autores
    let swiperAutores = new Swiper('.swiper-filter-autores',{
        slidesPerView: 'auto',
        spaceBetween:8
    })
}