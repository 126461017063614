import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from 'split-type';

gsap.registerPlugin(ScrollTrigger);

export default function splitLetters() {
    const splitAnimation = document.querySelectorAll('[js-split-letter]');

    splitAnimation.forEach((char, i) => {
        const text = new SplitType(char, { types: 'chars' });

        // Get the stagger and delay values from the data attributes, with defaults if not set
        const staggerValue = parseFloat(char.getAttribute('data-stagger')) || 0.02;
        const delayValue = parseFloat(char.getAttribute('data-delay')) || 0;

        gsap.from(text.chars, {
            scrollTrigger: {
                trigger: char,
                start: 'top 80%',
                end: 'top 20%',
            },
            opacity: 0.2,
            y: -20,
            scaleY: 0,
            transformOrigin: 'top',
            duration: 0.3,
            stagger: staggerValue,
            delay: delayValue, // Set delay for the animation
        });
    });
}
