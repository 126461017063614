
import Swiper from "swiper"
import { find } from "utils/dom"
import shareUrl from "../modules/sharedURL"
// swiper-dicas-leitura

export default function PageSingleblog() {
	const Page = find("[js-page='blogDetail']")
	if (!Page) return

	const contentPublicacao = document.querySelector('[js-publicacao-dinamico]')

	const itemsLeft = document.querySelectorAll('.single-content-wrapper h2')
	
	shareUrl()

	const swiperDicasLeitura = new Swiper(".swiper-dicas-leitura", {
		slidesPerView: 1.2,
		spaceBetween: 24,
		pagination: {
			clickable: true,
			el: '.swiper-dicas-leitura .swiper-pagination',
		},
		breakpoints: {
			767: {
				slidesPerView: 2,
				spaceBetween: 0,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 0,
			}
		}
	})


	if (itemsLeft && contentPublicacao) {
        const contentToInsert = [...itemsLeft].map((item, index) => {
            return `<p class="scroll-item" data-index="${index}">${item.innerText}</p>`
        }).join('')

        contentPublicacao.innerHTML = contentToInsert

        // Adiciona o evento de clique para cada item
        const scrollItems = contentPublicacao.querySelectorAll('.scroll-item')
        scrollItems.forEach((item, index) => {
            item.addEventListener('click', () => {
                // Remove a classe "ativa" de todos os itens
                scrollItems.forEach(i => i.classList.remove('active'))
                
                // Adiciona a classe "ativa" ao item clicado
                item.classList.add('active')
                
                // Calcula a posição para scroll considerando o top de 100px
                const targetPosition = itemsLeft[index].getBoundingClientRect().top + window.pageYOffset - 130

                // Realiza o scroll até a posição calculada
                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                })
            })
        })
    }

	
    // if (itemsLeft && contentPublicacao) {
    //     const contentToInsert = [...itemsLeft].map((item, index) => {
    //         return `<p class="scroll-item" data-index="${index}">${item.innerText}</p>`
    //     }).join('')

    //     contentPublicacao.innerHTML = contentToInsert

    //     const scrollItems = contentPublicacao.querySelectorAll('.scroll-item')

    //     const handleScroll = () => {
    //         itemsLeft.forEach((item, index) => {
    //             const rect = item.getBoundingClientRect()
    //             const windowHeight = window.innerHeight || document.documentElement.clientHeight

    //             if (rect.top >= 0 && rect.top <= windowHeight - 100) {
    //                 // Adiciona a classe "ativa" ao item correspondente
    //                 scrollItems.forEach(i => i.classList.remove('active'))
    //                 scrollItems[index].classList.add('active')
    //             }
    //         })
    //     }

    //     // Adiciona o evento de scroll
    //     window.addEventListener('scroll', handleScroll)

    //     // Chama handleScroll no carregamento da página para marcar o item correto
    //     handleScroll()

    //     // Adiciona o evento de clique para rolar até o elemento
    //     scrollItems.forEach((item, index) => {
    //         item.addEventListener('click', () => {
    //             const targetPosition = itemsLeft[index].getBoundingClientRect().top + window.pageYOffset - 100

    //             window.scrollTo({
    //                 top: targetPosition,
    //                 behavior: 'smooth'
    //             })

    //             // Remove a classe "ativa" de todos os itens e adiciona ao clicado
    //             scrollItems.forEach(i => i.classList.remove('active'))
    //             item.classList.add('active')
    //         })
    //     })
    // }

}