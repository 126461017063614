import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const ResultadoBusca = find("[js-page='resultado-busca']")
	if (!ResultadoBusca) return

	const itemsToScroll = document.querySelectorAll('div[js-scrollto]')

    const SwiperEducativos = new Swiper(".swiper-resultado-busca", {
		slidesPerView: 'auto',
		spaceBetween: 0,

		// centeredSlidesBounds: true,
		// centeredSlides: true,

	})

	itemsToScroll && itemsToScroll.forEach(anchor => {
		anchor.addEventListener('click', function (e) {
			e.preventDefault();
			const targetId = this.getAttribute('js-scrollto');
			const targetElement = document.getElementById(targetId);
		
			if (targetElement) {
				const yOffset = -120; // Margin top of 120px
				const yPosition = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
				console.log(yPosition)
				window.scrollTo({
					top: yPosition,
					behavior: 'smooth'
				});
			}
		});
	});
}