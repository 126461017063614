// Import GSAP
import gsap from 'gsap';
export default function(){

    document.addEventListener("DOMContentLoaded", function() {
		const menuItems = document.querySelectorAll('#main-nav ul li a');
		const megaMenus = document.querySelectorAll('.wrapper-mega-menu');
		const menu = document.querySelector('#main-nav');
		const publiqueMobile = document.querySelector('.publique-link-mobile');
		const btnMobile = document.querySelector('#btn-mobile');

		const accordionTitles = document.querySelectorAll('.accordion-title');
			// Level 2 Accordion
			const segundoNivelHeaders = document.querySelectorAll('.segundo-nivel header');


		let activeMenuTimeout;
		let lastScrollTop = 0;

		

		
		

		
		menuItems.forEach(item => {
			item.addEventListener('mouseover', function() {
				clearTimeout(activeMenuTimeout);
			
				const target = item.getAttribute('data-pai-target');
                menuItems.forEach(menuItem => menuItem.classList.remove('ativo'));
                item.classList.add('ativo');

				megaMenus.forEach(menu => {
					if (menu.getAttribute('data-pai') === target) {
						
						menu.classList.add('active');
						animateIfActive(menu);
					} else {
						menu.classList.remove('active');
					}
				});
			});

			item.addEventListener('mouseout', function() {
				activeMenuTimeout = setTimeout(() => {
					megaMenus.forEach(menu => {
						if (!menu.matches(':hover')) {
							menu.classList.remove('active');
						}
					});
                    item.classList.remove('ativo');
				}, 20); // Pequeno atraso para permitir a transição do mouse
			});
		});

		megaMenus.forEach(menu => {
			menu.addEventListener('mouseover', function() {
				clearTimeout(activeMenuTimeout);
				menu.classList.add('active');
			});

			menu.addEventListener('mouseout', function() {
                    activeMenuTimeout = setTimeout(() => {
                        if (!menu.matches(':hover')) {
                            menu.classList.remove('active');
                            const target = menu.getAttribute('data-pai');
						
                            menuItems.forEach(menuItem => {
                                if (menuItem.getAttribute('data-pai-target') === target) {
                                    menuItem.classList.remove('ativo');
									
                                }
                            });
                        }
                    }, 20); // Pequeno atraso para permitir a transição do mouse
                });
		});

		accordionTitles.forEach(title => {
			title.addEventListener('click', function() {
				const content = title.nextElementSibling;
				const plusIcon = title.querySelector('.plus-icon-menu');
				const minusIcon = title.querySelector('.minus-icon-menu');
	
				// Toggle visibility of the parent content
				content.classList.toggle('hidden');
				plusIcon.classList.toggle('hidden');
				minusIcon.classList.toggle('hidden');
	
				// Close any open inner accordions when parent is clicked
				const innerHeaders = content.querySelectorAll('.segundo-nivel header');
				innerHeaders.forEach(innerHeader => {
					const innerContent = innerHeader.nextElementSibling;
					const innerPlusIcon = innerHeader.querySelector('.plus-icon-menu');
					const innerMinusIcon = innerHeader.querySelector('.minus-icon-menu');
	
					// Close the inner content if it's open
					if (!innerContent.classList.contains('hidden')) {
						innerContent.classList.add('hidden');
						innerPlusIcon.classList.remove('hidden');
						innerMinusIcon.classList.add('hidden');
					}
				});
			});
		});

		segundoNivelHeaders.forEach(header => {
			header.addEventListener('click', function() {
				const content = header.nextElementSibling;
				const plusIcon = header.querySelector('.plus-icon-menu');
				const minusIcon = header.querySelector('.minus-icon-menu');
	
				// Toggle visibility of the inner content
				content.classList.toggle('hidden');
				plusIcon.classList.toggle('hidden');
				minusIcon.classList.toggle('hidden');
			});
		});



		function animateIfActive(element) {
			if (element.classList.contains('active')) {
				// Initial states
				const initialClipPath = 'polygon(0 0, 100% 0, 100% 0%, 0 0%)';
				const initialVisibility = 'hidden';
				const initialOpacity = 0;
				
				// Final states
				const finalClipPath = 'polygon(0 0, 100% 0, 100% calc(100% + 15px), 0 calc(100% + 15px))';
				const finalVisibility = 'visible';
				const finalOpacity = 1;
		
				// Apply the initial styles directly
				// element.style.clipPath = initialClipPath;
				element.style.visibility = initialVisibility;
				element.style.opacity = initialOpacity;
				element.style.height = '0';
				const nextEl = element.querySelectorAll('.container')
				// Animate to final states
				gsap.to(element, {
					duration: 0.5,
					// clipPath: finalClipPath,
					visibility: finalVisibility,
					opacity: finalOpacity,
					height: 'auto',
					y: 0,
					ease: 'power2.out',
					onStart: () => {
						// Ensure the element is visible and ready for animation
						element.style.visibility = finalVisibility;
					},
					onComplete: () => {
						element.style.height = '';
						element.style.opacity = '';
						element.style.visibility = '';
					}
				})
				gsap.set(nextEl, { clipPath: 'inset(0 100% 0 0)', y: 20 , opacity: 0})
				gsap.to(nextEl, { clipPath: 'inset(0 0% 0 0)', y:0,duration:0.8, opacity:1, delay:0.2, ease:'power2.out'})
				// gsap.from( nextEl, {x:-10,opacity:0, delay:0.2 })
			}
		}
	
		
		

		window.addEventListener('scroll', function() {
			const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
			if (window.scrollY > 50) { 
				document.body.classList.add('scrolled')
				menu.classList.add('scrolled');
			} else {
			document.body.classList.remove('scrolled')
			menu.classList.remove('scrolled');
			}

			if (currentScroll > lastScrollTop) {
			// Scrolling down
			publiqueMobile.classList.add('esconde-botao');
			} else {
			// Scrolling up
			publiqueMobile.classList.remove('esconde-botao');
			}
			  
			lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
		
		});
	});
}