import { find } from "utils/dom"

export default function PageMateriasGratuitos(){
const Page = find("[js-page='materias-gratuitos']")
if (!Page) return


const formMateriais = document.querySelector('#form-materias-categoria')

formMateriais && formMateriais.addEventListener('change', () => {
    formMateriais.submit();
})
}