import Swiper from "swiper"
import { find } from "../utils/dom"
import shareUrl from "../modules/sharedURL";

export default function SingleAutor(){

    const Page = find("[js-page='singleAutor']")
	if (!Page) return

    const tabsFilter = document.querySelectorAll('[js-filter-links] .tab-autor');
    const tabsContent = document.querySelector('[js-filter-items]');
    const novoLivroLinks = document.querySelectorAll('.variante-wrapper [data-link]')
    function startTabs(Items, firstItemActive, display){
        if (Items && firstItemActive) {
            // Set the initial state to show the first group
            const initialGroup = firstItemActive.querySelector('[data-format]');
            const firstAbove = document.querySelector('[js-filter-links] [data-format]');
            if(firstAbove){
                firstAbove.classList.add('active')
            }
            if (initialGroup) {
                initialGroup.style.display = display;
            }
        
            Items.forEach(item => {
                item.addEventListener('click', (e) => {
                    // Remove active class from all items
                    Items.forEach(i => i.classList.remove('active'));
                    // Add active class to the clicked item
                    e.currentTarget.classList.add('active');
        
                    const selectedFormat = e.currentTarget.getAttribute('data-format');
                    const filterGroups = firstItemActive.querySelectorAll('[data-format]');
                  
                    filterGroups.forEach(group => {
                     
                        if (group.getAttribute('data-format') === selectedFormat) {
                            group.style.display = display;  // Show the group
                        } else {
                            group.style.display = 'none';  // Hide the group
                        }
                    });
                });
            });
        }
    }


  


    function swapLinkBuy(){
        if(novoLivroLinks){
            novoLivroLinks.forEach(link =>{
                link.addEventListener('click', (e) =>{
                    novoLivroLinks.forEach(i =>i.classList.remove('active'))
                   link.classList.add('active')

                   const url = link.dataset.link
                
                   const buttonBuy = document.querySelector('#elementoBuy')
                   buttonBuy.href =  url
                })
            })
        }
    }

    // swapLinkBuy()
    shareUrl()
    startTabs(tabsFilter, tabsContent,'block')

    

    let singleAutorTab = new Swiper('.swiper-tab-autor',{
        slidesPerView: 'auto',
        breakpoints:{
            600:{
                slidesPerView: 4,

                spaceBetween: 32
            }
        }
    })
}
// swiper-tab-autor