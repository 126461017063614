import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


export default function animationMenu(){
    const menu = document.querySelector('#main-nav');
    const menuMobileBtn = document.getElementById("btn-mobile");
    const searchMobile = document.getElementById("search-mobile");
    const searchContent = document.querySelector('#search-mobile-js')
    const searchInput = document.querySelector('#search-mobile-js input');
    let overlay;
    // Set initial states
    gsap.set('#menu-mobile', { autoAlpha: 0 });
    gsap.set('#menu-mobile-container', { x: '100%' });
    
    // Create a timeline for the menu animation
    const menuTimeline = gsap.timeline({ paused: true, reversed: true });
    
    menuTimeline
      .to('#menu-mobile', {
        autoAlpha: 1,  // Fade in the dark overlay
        duration: 0.3,
        ease: "power2.inOut"
      })
      .to('#menu-mobile-container', {
        x: '0%',  // Slide in the menu container
        duration: 0.5,
        ease: "power2.inOut"
      }, "-=0.3")  // Overlap with the previous animation
      .from('.accordion-item', {
        y: 20,
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
        stagger: 0.1  // Stagger the items for a cascading effect
      }, "-=0.2");
    
    // Toggle animation on button click
    menuMobileBtn && menuMobileBtn.addEventListener("click", () => {
      menu.classList.toggle('active');

      if (menuTimeline.reversed()) {
        window.lenisInstance.stop();
        menuTimeline.play()
      } else {
        window.lenisInstance.start();
        menuTimeline.reverse();
      }

      // menuTimeline.reversed() ? menuTimeline.play() : menuTimeline.reverse();
    });
    function toggleSearch() {
      if (searchContent.classList.contains('active')) {

        // Fechar o conteúdo de pesquisa e remover o overlay
        searchContent.classList.remove('active');
        if (overlay) {
          document.body.removeChild(overlay);
          overlay = null;
        }
        document.removeEventListener('click', handleOutsideClick);
        window.lenisInstance.start();
      } else {
        // Abrir o conteúdo de pesquisa e adicionar o overlay
        
        overlay = document.createElement('div');
        overlay.className = 'overlay-search';
        document.body.appendChild(overlay);
    
        searchContent.classList.add('active');
        const searchInput = document.querySelector('#search-mobile-js input');
        if (searchInput) {
          searchInput.focus();
        }
        window.lenisInstance.stop();
        // Adiciona o listener para fechar ao clicar fora
        setTimeout(() => {
          document.addEventListener('click', handleOutsideClick);
        }, 0);
      }
    }
    
    function handleOutsideClick(event) {
      if (!searchContent.contains(event.target) && event.target !== searchMobile) {
        toggleSearch();
      }
    }
    
    searchMobile && searchMobile.addEventListener('click', (e) => {
      e.preventDefault();
      toggleSearch();
    });
    
    // Close the menu when clicking the overlay
    const menuMobile = document.getElementById('menu-mobile')
    menuMobile && menuMobile.addEventListener('click', (e) => {
      if (e.target === document.getElementById('menu-mobile')) {
        menuTimeline.reverse();
        menu.classList.remove('active');
      }
    });





    document.querySelectorAll('.accordion-title').forEach((accordionTitle) => {
        const accordionItem = accordionTitle.closest('.accordion-item');
        const accordionContent = accordionItem.querySelector('.accordion-content');
        const plusIcon = accordionItem.querySelector('.plus-icon-menu');
        const minusIcon = accordionItem.querySelector('.minus-icon-menu');
      
        // Set initial states
        gsap.set(accordionContent, { height: 0, opacity: 0, display: "none" });
      
        accordionTitle.addEventListener('click', () => {
          const isOpen = accordionItem.classList.contains('active');
      
          // Toggle accordion state
          accordionItem.classList.toggle('active');
      
          if (isOpen) {
            // Collapse animation
            gsap.to(accordionContent, {
              height: 0,
              opacity: 0,
              duration: 0.3,
              ease: "power2.inOut",
              onComplete: () => gsap.set(accordionContent, { display: "none" })
            });
           
          } else {
            // Expand animation
            gsap.set(accordionContent, { display: "block" });
            gsap.to(accordionContent, {
              height: "auto",
              opacity: 1,
              duration: 0.5,
              ease: "power2.inOut",
              onComplete: () => gsap.set(accordionContent, { height: "auto" })
            });
          }
        });
      });
      

      document.querySelectorAll('.segundo-nivel').forEach((secondLevel) => {
        const secondLevelHeader = secondLevel.querySelector('header');
        const secondLevelContent = secondLevel.querySelector('.content-segundo-nivel');
        const plusIcon = secondLevelHeader.querySelector('.plus-icon-menu');
        const minusIcon = secondLevelHeader.querySelector('.minus-icon-menu');
      
        // Set initial states
        gsap.set(secondLevelContent, { height: 0, opacity: 0, display: "none" });
      
        secondLevelHeader.addEventListener('click', () => {
          const isOpen = secondLevel.classList.contains('active');
      
          // Toggle second-level accordion state
          secondLevel.classList.toggle('active');
      
          if (isOpen) {
            // Collapse second-level accordion
            gsap.to(secondLevelContent, {
              height: 0,
              opacity: 0,
              duration: 0.3,
              ease: "power2.inOut",
              onComplete: () => gsap.set(secondLevelContent, { display: "none" })
            });
          } else {
            // Expand second-level accordion
            gsap.set(secondLevelContent, { display: "block" });
            gsap.to(secondLevelContent, {
              height: "auto",
              opacity: 1,
              duration: 0.5,
              ease: "power2.inOut",
              onComplete: () => gsap.set(secondLevelContent, { height: "auto" })
            });
          }
        });
    });
    
}